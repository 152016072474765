<template>
    <div class="main">
        <div class="box">
            <div class="sky"></div>
            <iframe :src="url" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    name: 'index',
    data() {
        return {};
    },
    computed: {
        ...mapState("im", ["url"])
    },
    methods: {},
};
</script>

<style scoped lang="scss">
.main{
    display: flex;
    align-items: center;
    justify-content: center;
    // background: #f66;
    height: 100vh;
}
.box {
    width: 864px;
    height: 450px;
    position: relative;
    overflow: hidden;
    // background: #6f6;
    border-radius: 10px;
    box-shadow: 0px 6px 10px 0px rgba(0, 99, 255, 0.06);
    // box-shadow: 1px 2px 1px #000000fd;
    // .sky{
    //     height: 36px;
    //     width: 100%;
    //     position: absolute;
    //     left: 0;
    //     bottom: calc(150px - 36px);
    //     z-index: 100;
    //     background: #fff;
    // }
    iframe {
        width: 100%;
        height: 500px;
        position: absolute;
        top: -50px;
        left: 0;
    }

//    .sky{
//         width: 100%;
//         height: 36px;
//         background: #F3F6F8;
//         position: absolute;
//         bottom: 150px;
//         left: 0;
//     } 
}
</style>